import { PageProps } from "gatsby";
import React from "react";

import { NamesOfNotesAndIntervals } from "../components/pages/NamesOfNotesAndIntervals";

import { snProps } from "../js/utils";

const NamesOfNotesAndIntervalsPage = (props: PageProps) => (
  <NamesOfNotesAndIntervals {...{ ...snProps, ...props }} />
);

export default NamesOfNotesAndIntervalsPage;
